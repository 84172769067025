<script>
import {  mapGetters } from 'vuex';
import http from '@commons/http';
import Timer from '@components/login/recover/Timer';
import Recaptcha from '@components/Recaptcha';
import FaleConosco from '@components/login/FaleConosco';


export default {
	name: 'LoginEmailCode',
	components: {
		Timer,
		Recaptcha,
		FaleConosco,
	},
	data() {
		return {
			notification: false,
			form: {
				codigo: ""
			},
			timerExpired: false,
			resendCode: false,
			timer: 10,
			timerCount: this.timer,
			loading: false,
		}
	},
	computed: {
		...mapGetters('senha', [
			'getEmailMasked',
			'getCpf',
			'getExpTime',
			'getReseId',
		]),
		expiration() {
			if (this.getExpTime == null || this.getExpTime <= 0) return 0;

			return this.getExpTime * 60;
		},
		enableConfirm(){
			return this.form.codigo.length == 6;
		}
	},

	watch: {
		timerCount: {
			handler(value) {
				if (value > 0 && !this.resendCode) {
					setTimeout(() => {
						this.timerCount--;
					}, 1000);
				} else {
					this.resendCode = true;
				}
			},
			immediate: true
		},
	},

	mounted() {
		if (!this.getCpf || this.getCpf == 'null') {
			this.goto();
		}
	},

	created() {
		this.startTimer();
	},

	methods: {
		goto() {
			this.$router.push({ name: 'login.home' });
		},
		new_pass() {
			this.$router.push({ name: 'login.new_pass' });
		},
		async verficaCodigoEmail() {
			if (this.notification) this.notification.close();

			let data_params = new URLSearchParams(Object.entries(this.form));
			data_params.append('cpf', this.getCpf);
			this.loading = true;
			try {
				const captcha = await this.$refs.grecaptcha.generate();

				data_params.append('captcha', captcha);
			} catch (error) {

				console.log(error);
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Recaptcha inválido!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
				return;
			}
			http.post('/v1/auth/senha/metodos/email/verifica', data_params).then(({ data }) => {
				this.loading = false;
				if (data.valido) {
					this.new_pass();
				} else {
					this.notification = this.$buefy.notification.open({
						indefinite: true,
						queue: false,
						message: `Código inválido!`,
						position: 'is-top',
						type: 'is-danger',
						hasIcon: true
					});
				}
			}, () => {
				this.loading = false;
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Erro interno!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
			});
		},
		async resendEmail() {
			if (this.notification) this.notification.close();
			const body = new FormData();
			body.append('rese_id', this.getReseId);

			this.loading = true;
			try {
				const captcha = await this.$refs.grecaptcha.generate();

				body.append('captcha', captcha);
			} catch (error) {

				console.log(error);
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Recaptcha inválido!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
				return;
			}

			http.post('/v1/auth/senha/metodos/email/resend', body).then(({ data, status }) => {
				this.loading = false;
				if (status >= 400) {
					console.log(data.message);
				}
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Código reenviado!`,
					position: 'is-top',
					type: 'is-success',
					hasIcon: false
				});

				this.startTimer();

			}, () => {
				this.loading = false;
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Ocorreu um erro!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
			});
		},
		startTimer() {
			this.timerCount = this.timer;
			this.resendCode = false;
		},
		expired() {
			this.timerExpired = true;
			if (this.notification) this.notification.close();

			this.notification = this.$buefy.notification.open({
				indefinite: true,
				queue: false,
				message: `Código expirado!`,
				position: 'is-top',
				type: 'is-danger',
				hasIcon: false
			});
		}
	},
}
</script>


<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Digite no campo abaixo o código que você recebeu:
			</h2>
		</div>
		<div class="has-text-centered mt-3">
			<Timer
				:time="expiration"
				@expired="expired"
			/>
		</div>

		<div class="columns mt-1">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<div class="columns is-multiline is-text-centered">
					<form
						novalidate
						class="form column is-12 pb-0 has-text-centered"
						@submit.prevent="verficaCodigoEmail"
					>
						<div class="column is-12">
							<div class="">
								<input
									v-model="form.codigo"
									pattern="\d*"
									class="input is-large input-cod"
									type="tel"
									maxlength="6"
								>
							</div>

							<div class="confirm-button has-text-centered mt-5">
								<button
									class="button is-primary is-rounded"
									:disabled="timerExpired || !enableConfirm"
								>
									Prosseguir
								</button>
							</div>
						</div>
					</form>

					<form
						class="column is-12 mt-0"
						@submit.prevent="resendEmail"
					>
						<div class="has-text-centered">
							<h4>Não recebeu o código?</h4>

							<button
								class="resend-button button is-primary is-rounded is-outlined is-small mt-2"
								:disabled="!resendCode || timerExpired"
							>
								<span>Reenviar por e-mail </span><span v-if="!resendCode"> ({{ timerCount }})</span>
							</button>
						</div>
					</form>

					<div class="column is-12 has-text-centered mt-4">
						<button
							class="button is-primary is-rounded is-outlined"
							@click="goto"
						>
							Voltar para o login
						</button>
					</div>
				</div>

				<div class="mt-6">
					<h4 class="has-text-centered">
						Não reconhece seu e-mail ou não possui mais acesso a ele?
					</h4>
					<FaleConosco />
				</div>
			</div>
		</div>

		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<Recaptcha ref="grecaptcha" />
	</div>
</template>

<style scoped>
.notification {
	background-color: transparent;
}

.input-cod {
	text-align: center;
}

@media screen and (max-width: 768px) {
	.resend-button {
		min-width: 60%;
	}
	.confirm-button {
		min-width: 80%;
	}
}


.resend-button {

	max-width: 30%;
	padding: 0;
	border:0;
}
</style>
