<template>
    <div>
        <span>Tempo restante: </span>
        <span><strong>{{ timer }}</strong></span>
    </div>
</template>

<script>
    export default {
        props: {
            time: {
                type: Number,
                required: true,
            }
        },
        data(){
            return {
                intervalCounter: 0,
                remainingTime: 0,
            };
        },
        computed: {
            timer() {
                if(this.remainingTime <= 0) {
                    return '00:00'
                }
                return String(Math.floor(this.remainingTime / 60)).padStart(2, "0") + ":" + String(this.remainingTime % 60).padStart(2, "0");
            }
        },
        methods: {
            decreaseTimer(){
                this.remainingTime--; 
                if(this.remainingTime <= 0) {
                    clearInterval(this.intervalCounter);
                    this.intervalCounter = 0;
                    this.$emit('expired');
                }
            }
        },
        created(){
            this.remainingTime = this.time;
            this.intervalCounter = setInterval(this.decreaseTimer, 1000);
        }

    }
</script>

<style lang="scss" scoped>

</style>